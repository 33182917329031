@use "../../../../../tokens";

.stepContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: tokens.$spacing-md;

  h1 {
    font: tokens.$text-title-2xs;

    @media screen and (min-width: tokens.$screen-md) {
      font: tokens.$text-title-xs;
    }
  }

  form {
    width: 100%;
  }

  .explainerTrigger {
    background-color: transparent;
    border-style: none;
    color: tokens.$color-blue-50;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: tokens.$color-blue-70;
    }
  }
}

.dialogContents {
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-lg;
  font: tokens.$text-body-md;
  color: tokens.$color-grey-50;

  .confirmButtonWrapper {
    align-self: center;
    display: flex;
    flex-direction: column;
    min-width: tokens.$content-xs;
    padding-block-start: tokens.$spacing-md;
  }

  .list {
    list-style-position: inside;
    display: flex;
    flex-direction: column;
    gap: tokens.$spacing-md;
    padding: 0;

    li::marker {
      display: inline-block;
      font-weight: 700;
    }
  }
}

.stepButtonWrapper {
  align-items: center;
  display: flex;
  gap: tokens.$spacing-md;
  justify-content: flex-end;
  padding-block: tokens.$layout-xs;
  width: 100%;

  @media screen and (min-width: tokens.$screen-sm) {
    padding-block-start: tokens.$layout-sm;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.inputContainer {
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: tokens.$spacing-lg tokens.$spacing-xl;
  padding: tokens.$spacing-lg 0;

  @media screen and (min-width: tokens.$screen-lg) {
    // The child items in the grid are input fields. Whether items marked as
    // “optional” are shown depends on the enabled variants in the
    // `welcome-scan-optional-info` experiment.
    // 1. First name
    // 2. Middle name (optional)
    // 3. Last name
    // 4. Name suffix (optional)
    // 5. Date of birth
    // 6. City and state
    & > :nth-child(odd) {
      grid-column: 1 / 4;
    }

    &:has(label[for="name_suffix"]) {
      // The field “last name” is only in an “odd” position if the experiment
      // `welcome-scan-optional-info` is enabled.
      & > :nth-child(odd) {
        &:has(> label[for="last_name"]) {
          grid-column: 1 / 5;
        }
      }
    }

    & > :nth-child(even) {
      grid-column: 4 / 7;

      // The field “suffix” is only being rendered if the experiment
      // `welcome-scan-optional-info` is enabled.
      &:has(> label[for="name_suffix"]) {
        grid-column: 5 / 7;
      }
    }
  }

  @media screen and (max-width: calc(tokens.$screen-lg - 1px)) {
    grid-template-columns: 1fr;
  }

  input {
    min-height: 3rem;

    &::placeholder {
      // overwrite default opacity for placeholders in Firefox
      opacity: 1;
    }
  }

  input[type="date"] {
    // remove grey background and align date input value on iOS
    background: transparent;
    &::-webkit-date-and-time-value {
      text-align: start;
    }
  }
}

.infoList {
  align-items: center;
  columns: 2;
  font: tokens.$text-body-lg;
  gap: tokens.$spacing-lg;
  list-style: none;
  margin: 0;
  padding-inline-start: var(--exposureDetailsIconWidth);
  padding: 0;

  @media screen and (max-width: tokens.$screen-md) {
    columns: 1;
  }
}

.infoItem {
  display: flex;
  gap: tokens.$spacing-sm;
  padding: tokens.$spacing-sm 0;

  // Force all fields related to the users name to be listed in the left column.
  &.isNameRelatedField {
    break-before: avoid;
  }
}
