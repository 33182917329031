@use "../../../tokens";

$button-size: 42px;

.trigger {
  background: white;
  border-radius: 50%;
  border-style: none;
  cursor: pointer;
  height: $button-size;
  padding: 0;
  width: $button-size;
  overflow: hidden;

  @media screen and (max-width: tokens.$screen-md) {
    margin-right: tokens.$spacing-sm;
  }

  &:focus,
  &:hover {
    outline: 2px solid tokens.$color-purple-70;
  }
}

.menuList {
  border-radius: tokens.$border-radius-md;
  box-shadow: tokens.$box-shadow-md;
  font: tokens.$text-body-md;
  list-style-type: none;
  min-width: tokens.$content-xs;
  outline: 0;
  padding: tokens.$spacing-sm 0;
  position: relative;
  right: 0;

  &::before {
    $tooltip-size: 12px;
    background-color: white;
    content: "";
    height: $tooltip-size;
    position: absolute;
    right: calc(tokens.$spacing-md + $button-size * 0.5);
    top: 0;
    transform: translateY(-50%) rotate(45deg);
    width: $tooltip-size;

    @media screen and (max-width: tokens.$screen-md) {
      right: calc($button-size * 0.5);
    }
  }
}

.menuItemWrapper {
  cursor: pointer;
  padding: tokens.$spacing-sm tokens.$spacing-lg;
  position: relative;

  &:focus {
    outline: none;
    background: tokens.$color-grey-10;
  }

  &:first-of-type {
    // Margin to create visual space between the pseudo element and
    // the first item after it.
    margin-bottom: tokens.$spacing-sm;
    padding: calc(tokens.$spacing-sm * 1.5) calc(tokens.$spacing-md * 1.5);

    b {
      color: tokens.$color-purple-90;
      margin-bottom: tokens.$spacing-xs;
    }

    &::after {
      background: tokens.$monitor-gradient;
      content: "";
      height: 2px;
      left: 0;
      bottom: 0;
      position: absolute;
      width: 100%;
    }
  }
}

.menuItemCta {
  align-items: center;
  background: none;
  border: 0;
  color: tokens.$color-black;
  cursor: pointer;
  display: flex;
  gap: tokens.$spacing-sm;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
